import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import ResultCard from "../components/resultComponents/ResultCard"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import { Link } from "gatsby"
import ArticleImage from "../components/images/ArticleImage"
import { OutboundLink } from "gatsby-plugin-gtag"
import { AdserviceCompare } from "../components/CompareAdsense"

const FrontPage = ({ data }) => {
  const plansHook = data.allPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        border={true}
        pageName={"dna"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Paras DNA liittymätarjous 2020 - Katso täältä!"
        desc="DNA puhelinliittymät ovat varma ja edullinen valinta. Katso täältä parhaat liittymät ja tarjoukset joista valitset sopivimman liittymätarjouksen juuri sinulle. Katso täältä!"
      />
      <Container>
        <Header1>
          DNA liittymätarjous - Puhelinliittymä jokaiseen tarpeeseen{" "}
        </Header1>
        <TextArea>
          <b>Alapuolelta löydät juuri sinulle sopivan liittymän DNA:lta</b>.
          Valitse rajaton jolla surffaat netissä ja puhut tai lähetät viestejä
          rajattomasti kiinteään kuukausi hintaan tai valitse Jämpti jos haluat
          maksaa vain käytön mukaan.
        </TextArea>
        <Header3>Tutustu alapuolelta DNA:n liittymiin</Header3>
        <AdserviceCompare />
        <RowContainer title={"Tutustu myös muihin operaattoreihin:"}>
          <Button link={"/elisa/"} title={"Elisa"} />
          <Button link={"/telia/"} title={"Telia"} />
          <Button link={"/moi/"} title={"Moi Mobiili"} />
        </RowContainer>
        <Header2>
          DNA – mobiilihaastajasta Suomen johtavaksi tietoliikennekonserniksi
        </Header2>
        <ArticleImage imgName="dnaetusivu.jpg" />
        <TextArea>
          DNA:n tarina starttasi tasan 20 vuotta sitten, kun kolme
          matkaviestinnän yhteistyöyhtiötä perustettiin Finnet-ryhmän
          puhelinyhtiöiden laajentamiseksi. Muutamien vaiheiden kautta näistä
          muotoutui matkaviestintäliiketoimintaan yhtiö DNA Finland Oy. Alussa
          yrityksen omisti noin 40 suomalaista puhelinyhtiötä. Koko Suomen
          kattavan matkapuhelinpalvelun avaamisen valmistelut aloitettiin vuonna
          2000. Verkko avattiin ja ensimmäisten DNA-liittymien myynti
          aloitettiin helmikuussa 2001.
        </TextArea>
        <TextArea>
          Nykyään DNA tuottaa puhe-, data- ja tv-palveluja. Yrityksellä oli
          joulukuussa 2018 yli 4 miljoonaa liittymäasiakkuutta, ja se on myös
          Suomen suurin kaapelioperaattori. DNA:n konserniin kuuluu myös Suomen
          laajin matkapuhelimia myyvä myymäläketju, DNA Kauppa. DNA:sta tuli
          pörssiyhtiö vuonna 2016 yrityksen listauduttua Helsingin pörssiin.
        </TextArea>
        <Header3>Miksi valita DNA?</Header3>
        <TextArea>
          DNA on kasvanut vuosien saatossa pienestä mobiilihaastajasta yhdeksi
          Suomen johtavista tietoliikennekonserneista. Yrityksen toiminnan
          keskiössä on koko sen olemassaoloajan ollut kustannustehokkuus,
          nopealiikkeisyys ja innovatiivisuus. Kuten yrityksen
          markkinoinnistakin näkee, nopeus, rohkeus ja mutkattomuus ovat
          keskeisiä arvoja sen kaikessa toiminnassa. DNA haluaa tarjota
          asiakkailleen korkealaatuiset yhteydet, joiden kautta asiakkaat voivat
          käyttää haluamiaan digitaalisia palveluja aina puheluista netti-tv:n
          katsomiseen parhailla mahdollisilla päätelaitteilla. Yrityspalveluiden
          osalta DNA näkee tärkeimpänä kohteena ajasta ja paikasta
          riippumattoman uuden työn mahdollistamisen. Valitse paras DNA
          liittymätarjous ja tue kotimaista puhelinoperaattoria.
        </TextArea>
        <TextArea>
          DNA on luonut koko toiminta-ajatuksensa asiakkaan ympärille, ja
          yrityksen strategisena pyrkimyksenä on tehdä asiakkaidensa arjesta
          aina mahdollisimman mutkatonta. Näin jokainen heistä on vapaa
          keskittymään itselleen tärkeisiin asioihin. Yrityksen visiona on omata
          markkinoiden tyytyväisimmät asiakkaat. DNA pyrkii rakentamaan
          eriomaista asiakaskokemusta muutaman keskeisen tukijalan varaan.
          Näihin kuuluvat korkealaatuiset matkaviestinverkot, kiinteät verkot,
          motivoitunut ja asiakaspalveluhenkinen henkilöstö, korkeatasoinen
          data-analytiikka sekä lisäksi DNA:n toiminnalle ominainen
          haastajakulttuuri, joka tulee esiin toiminnan ketteryytenä ja
          kustannustehokkuutena.
        </TextArea>
        <TextArea>
          DNA:lla on töissä 1600 ammattilaista huolehtimassa
          asiakaskokemuksesta. Yritys on ottanut johtamiseen modernin otteen,
          jossa korostuu työntekijään luottaminen. Se, ettei tuijoteta
          kellonviisareita, vaan ennemminkin arvioidaan työn tuloksia, luo hyvää
          ilmapiiriä ja työntekijöille tunteen vastuusta ja vapaudesta. DNA:lla
          myös selkeästi tehdään asioita oikein, sillä yritys valittiin Suomen
          parhaaksi työpaikaksi helmikuussa 2020{" "}
          <a href="https://www.greatplacetowork.fi/">The Great Place to Work</a>
          -tutkimuksessa. DNA on esitellyt täysin uudenlaisia, perheystävällisiä
          käytäntöjä työntekijöilleen, kuten isovanhempainvapaan. Yritys myöntää
          ensimmäisenä maailmassa isovanhemmaksi tuleville työntekijöilleen
          viikon palkallisen isovanhempainvapaan.
          <ul>
            <li>
              DNA on eräs Suomen suurimmista tietoliikennepalveluiden
              tarjoajista.
            </li>
            <li>
              DNA:lla on oma maan kattava matkaviestintäverkko sekä Suomen
              laajin valokuitupohjainen kaapeliverkko.
            </li>
            <li>
              DNA:lla toiminnot on jaettu kuluttajaliiketoimintaan sekä
              yritysliiketoimintaan.
            </li>
            <li>DNA:lla on yli 4 miljoonaa liittymäasiakkuutta.</li>
            <li>
              DNA työllistää noin 1600 matkaviestintäalan ammattilaista
              tavoitteenaan erinomaisen asiakaskokemuksen luominen.
            </li>
            <li>
              DNA valittiin Suomen parhaaksi työpaikaksi The Great Place to Work
              -tutkimuksessa vuonna 2020.
            </li>
          </ul>
        </TextArea>

        <TextArea>
          DNA:lla on alati käynnissä erilaisia hankkeita, kuten{" "}
          <a href="https://www.dna.fi/5g" rel="nofollow">
            5G-palveluiden
          </a>
          käyttöönotto. Tämä toteutetaan lisäämällä DNA:n verkkoon 5G-kyvykästä
          teknologiaa, ja kasvattamalla verkon kapasiteettia. Yritys on jo
          rakentanut 5G-verkon esimerkiksi osana Porin SuomiAreena Älykotia. 5G
          verkko on saatavilla esimerkiksi Helsingin ydinkeskustassa ja
          vähitellen myös muissa suurissa kaupungeissa. DNA tuo markkinoille
          myös kiinteän 5G:n, eli pientalojen nopean laajakaistan. Tämä
          mahdollistaa laajakaistayhteyden alueille, joille ei saa valokuitua.
          5G palvelut yleistynevät vuonna 2020, kun kohtuuhintaisempia ja
          teknologialtaan parempia päätelaitteita tulee laajemmin markkinoille.
        </TextArea>
        <Header2>Millaisia liittymiä DNA:lla on?</Header2>
        <ArticleImage imgName="dnaliittymat.jpg" />
        <TextArea>
          <OutboundLink href="/tilaa/dnapuheplus" rel="nofollow">
            DNA Puhe Plus
          </OutboundLink>
          on liittymä sellaiselle käyttäjälle, joka käyttää nettiä harvakseltaan
          ja soittelee vain satunnaisesti. Tämän liittymän haltijat maksavat
          puheluista ja viesteistä käytön mukaan kotimaassa ja EU/ETA-alueella..
          Liittymä sisältää 2 Gt/kk EU-datapaketin, ja netin maksiminopeus on
          0,256 Mbit/s. Puheluiden hinta on alk. 0,07 € /min ja tekstiviestien
          0,07 €/kpl. DNA Puhe Plus -liittymä on hyvä myös matkustaessasi, sillä
          se sisältää EU-datapaketin, joka mahdollistaa netin käytön
          EU/ETA-alueella. Lisäksi EU/ETA-alueella puhelut ja viestit
          laskutetaan kotimaan hinnalla.
        </TextArea>
        <TextArea>
          <OutboundLink href="/tilaa/dnajampti" rel="nofollow">
            DNA Jämpti
          </OutboundLink>{" "}
          on paras liittymä käyttäjälle, jolle netti ei ole pääasia ja
          puheluiden määrä jää vähäiseksi. Liittymän netti on rajaton, ja
          puheluista ja viesteistä maksetaan käytön mukaan. Hinta on aina
          jämpti: normaalihintaiset puhelut maksavat 0,07€ per alkava minuutti,
          ja tekstiviestit 0,07€ /kpl. Liittymässä ei ole datakattoa, ja sen
          maksiminopeus on 1 Mbit/s. DNA Jämpti on hyvä myös reissun päällä,
          sillä se sisältää 5 Gt:n suuruisen kuukausittaisen datapaketin, joka
          sisältää tiedonsiirron myös EU- ja ETA-alueilla.
        </TextArea>
        <TextArea>
          <OutboundLink href="/tilaa/dnarajaton" rel="nofollow">
            DNA Rajaton
          </OutboundLink>{" "}
          on paras vaihtoehto käyttäjille, jotka haluavat soitella ja tekstailla
          ilman huolta kasvavasta puhelinlaskusta, mutta käyttävät kuitenkin
          nettiä varsin hillitysti, tai eivät ollenkaan. DNA Rajaton sisältää
          nimensä mukaisesti rajattomasti kaikkea kotimaassa sekä EU- ja
          ETA-alueella. Liittymässä on kiinteä kuukausihinta, joka sisältää
          rajattomasti puheluja sekä teksti- ja multimediaviestejä. DNA Rajaton
          sisältää myös 10 Gt kuukausittaisen EU-datapaketin. EU-alueella
          matkustaessasi voit siis käyttää nettiä ilman lisäkustannuksia aina 10
          gigaan asti joka kuukausi.
        </TextArea>
        <TextArea>
          <OutboundLink
            href="https://www.dna.fi/prepaid-liittymat"
            rel="nofollow"
          >
            Prepaid-liittymä
          </OutboundLink>{" "}
          on kätevä silloin, kun et halua huolehtia puhelinlaskuista. Puhelimen
          käytöstä maksetaan aina etukäteen, joten puhelinkuluja on helppoa
          kontrolloida, eikä ikäviä yllätyksiä tapahdu. DNA:n Prepaid-liittymä
          on helppo hankkia myös tilapäiskäyttöön. Liittymän aloituspakkaus
          täytyy kuitenkin hankkia ennen käyttöönottoa. DNA:lla on kolme
          erilaista prepaid-liittymää aloituspakkauksineen: DNA Super Prepaid,
          DNA Rajaton Prepaid ja DNA Dataprepaid. Kaikki DNA:n prepaid-liittymät
          ovat nopeita DNA 4G -liittymiä ilman datakattoa, ja niillä voi puhua
          ja tekstata EU-alueella kotimaan hinnalla.
        </TextArea>
        <Header3>Liittymiä myös vaativampaan käyttöön</Header3>
        <ArticleImage imgName="dnaliittymatrajaton.jpg" />
        <TextArea>
          DNA:lta löytyy sopivia liittymävaihtoehtoja myös hieman vaativammille
          käyttäjille. Yksi näistä on{" "}
          <OutboundLink href="/tilaa/dnanopea4gjampti/" rel="nofollow">
            DNA Nopea 4G Jämpti
          </OutboundLink>
          . Tämä liittymä on paras vaihtoehto kaikille käyttäjille, jotka
          viettävät runsaasti aikaa netissä, mutta eivät kovinkaan usein sorru
          puhumaan maratonpuheluita ja tekstailevatkin harvakseltaan. DNA Nopea
          4G Jämpti -liittymässä maksetaan puheluista ja tekstiviesteistä, kuten
          nimikin sen sanoo, jämptisti käytön mukaan. Liittymä sisältää nopean
          4G-netin ilman datakattoa. DNA Nopea 4G Jämpti -liittymän netin
          maksiminopeus on 100 Mbit/s.
        </TextArea>
        <TextArea>
          <OutboundLink href="/tilaa/dnanopea4grajatonplus/" rel="nofollow">
            DNA Nopea 4G Rajaton Plus
          </OutboundLink>{" "}
          on liittymä, joka on nimensä mukaisesti sekä rajaton, että erittäin
          nopea. DNA Nopea 4G Rajaton Plus -puhelinliittymässä yhdistyvät
          nopeasti toimiva netti ja rajattomuuden huolettomuus. Liittymän
          omistajat voivat yhteen kiinteään kuukausihintaan soittaa, tekstata ja
          käyttää nettiä Suomessa juuri niin paljon kuin haluavat. DNA Nopea 4G
          Rajaton Plus -liittymän netin maksiminopeus on 150 Mbit/s, ja tämäkin
          liittymä sisältää 10 Gt:n suuruisen kuukausittaisen EU-datapaketin.
          Tästä johtuen liittymä on kätevä myös EU/ETA-alueella matkustettaessa.
        </TextArea>
        <TextArea>
          <OutboundLink href="/tilaa/dnasuper4grajaton/" rel="nofollow">
            DNA Super 4G Rajaton
          </OutboundLink>{" "}
          on hyvä liittymä kaikille käyttäjille, jotka inhoavat odottelua.
          Mikäli odottelu ei kiinnosta, tällä superliittymällä et ainakaan
          tuhlaa aikaasi matelevan netin parissa. DNA Super 4G Rajaton
          -liittymän omistajat puhuvat, tekstailevat ja surffailevat
          kuukausittain juuri niin paljon kuin haluavat. DNA Super 4G Rajaton
          tulee kiinteään kuukausihintaan ja sen sisältämän netin maksiminopeus
          on 300 Mbit/s. DNA Super 4G Rajaton on loistava valinta myös
          EU/ETA-alueella matkustettaessa, sillä liittymä sisältää muhkean 15
          Gt:n suuruisen kuukausittaisen EU-datapaketin.
        </TextArea>
        <TextArea>
          Kaikista nopein, joskin samalla myös kaikista hintavin DNA:n
          tarjoamista liittymävaihtoehdoista on{" "}
          <OutboundLink href="/tilaa/dnamax4grajaton/">
            DNA Max 4G Rajaton
          </OutboundLink>
          . DNA on antanut lupauksen tämän liittymän omistajille siitä, että he
          voivat aina olla varmoja, että käytössä on kaikkein paras mahdollinen
          DNA:n verkon 4G-yhteys, nyt ja tulevaisuudessa. DNA Max 4G Rajaton
          sisältää rajattoman tiedonsiirtonopeuden 4G-verkossa. Liittymä
          sisältää luonnollisesti myös rajattoman määrän puheluja ja viestejä
          sekä huippunopeaa nettiä kotimaassa. DNA Max 4G Rajaton sisältää myös
          20 Gt: suuruisen kuukausittaisen EU-datapaketin.
        </TextArea>
        <Header2>DNA liittymätarjous – "Elämä kallis, DNA halpa"</Header2>
        <ArticleImage imgName="dnatarjoukset.jpg" />

        <TextArea>
          DNA:n markkinointi ja mainonta on yrityksen ensihetkistä lähtien ollut
          räväkkää ja värikästä. Jo yrityksen tummanpinkki tunnusväri herättää
          huomiota ja erottuu edukseen{" "}
          <Link to={"/operaattorit/"}>muiden teleoperaattoreiden</Link> hieman
          geneerisestä massasta. Eräs DNA:n tunnetuimmista kampanjoista on ollut
          vuonna 2004 käynnistynyt "Elämä kallis, DNA halpa". Yhdessä kampanjan
          mainoksista mies osti pihalleen 500 puutarhatonttua ja naapurin
          päivitellessä niiden kallista hintaa mies totesi lakonisesti ”Elämä
          on”. Kampanjan tv-mainokset loppuivat aina tähän legendaariseen
          lausahdukseen, ja noussut ilmiö siivitti DNA:n liittymämyynnin
          nousuun.
        </TextArea>
        <TextArea>
          DNA tarjoaa runsaasti erilaisia laite- ja{" "}
          <Link to={"/liittymatarjous/"}>liittymätarjouksia.</Link>
          Yrityksen puhelinliittymän omistavat asiakkaat saavat puhelimet aina
          hieman edullisempaan DNA Asiakasetuhintaan. Kun puhelimen tilaa
          suoraan netistä DNA:n sivustolta, on toimitus ja palautus aina
          ilmainen. Sivustolta löytyy laaja valikoima niin matkapuhelimia,
          tietokoneita ja tabletteja, kuin televisioita ja oheislaitteitakin.
          Verkkosivulta saa näppärästi tilattua myös erilaisia lisälaitteita ja
          oheistarvikkeita. Parhaat laite- ja oheistarjoukset myydään usein
          loppuun nopeasti, joten tarjouksia kannattaa pitää silmällä ja
          lunastaa omansa nopeasti.
          <ul>
            <li>Verkkotilauksille ilmainen toimitus 1–3 päivässä</li>
            <li>Koroton ja kuluton DNA Maksuaika</li>
            <li>Tableteille pidennetty 3 vuoden DNA Takuu</li>
            <li>Puhelinliittymäasiakkaille edullinen Asiakasetuhinta</li>
          </ul>
        </TextArea>
        <TextArea>
          DNA:n mainonta on lähes poikkeuksetta herättänyt huomiota, joskin
          toisinaan myös hieman negatiivista sellaista. Esimerkiksi vuonna 2006
          pyörinyt "Kaamea kapula" -mainoskampanja perustui sanojen krapula ja
          kapula samankaltaisuuteen. Tämä mainos johti kuluttajien lähettämään
          lausuntopyyntöön mainonnan eettiselle neuvostolle, sillä mainoksella
          koettiin olevan huono vaikutus nuoriin. Mainonnan eettinen neuvosto
          kuitenkin päätyi tutkimuksessaan siihen lopputulokseen, etteivät
          ”Kaamea kapula” -kampanjan mainokset olleet mainonnan kansainvälisten
          perussääntöjen vastaisia. Tämäkin tapaus kasvatti DNA:n tunnettuutta
          entisestään, joten lopputulos oli DNA:n kannalta positiivinen.
        </TextArea>
        <TextArea>
          DNA yhdisti brändinsä, palvelumuotoilun sekä asiakasymmärryksen
          yhdeksi toiminnoksi syksyllä 2018. Toimenpide oli tulos yrityksen
          suorittamasta laajasta asiakkuustutkimuksesta. Tutkimuksessa korostui
          erittäin suuri tarve selkiyttää DNA:n peruspalveluita vielä enemmän,
          sekä luoda palveluita, jotka aidosti auttaisivat asiakkaita heidän
          perusarjessaan. Brändiä lähdettiin tämän jälkeen kehittämään tämän
          tutkimuksessa ilmenneen ytimen ympärille. DNA:n mukaan
          brändikehityksen, palvelumuotoilun ja asiakasymmärryksen yhdistäminen
          tarkoittaa muun muassa sitä, että asiakkaiden pompottelu vähenee DNA:n
          ottaessa tarvittaessa yhteyttä asiakkaisiin eikä toisinpäin.
        </TextArea>
        <Header3>DNA-liittymien lisäpalvelut</Header3>
        <TextArea>
          DNA tarjoaa liittymilleen myös laajan kattauksen erilaisia
          lisäpalveluita. Yritys tekee aktiivista asiakkuustutkimusta, ja tämä
          näkyy muun muassa palvelutarjonnan erinomaisuutena. Eräs tarjotuista
          palveluista on eSIM, eli digitaalinen SIM-kortti. Se korvaa
          perinteisen muovisen SIM-kortin ja mahdollistaa esimerkiksi työ- ja
          vapaa-ajan liittymän yhdistämisen samaan laitteeseen. Toistaiseksi
          eSIM-valmius on vasta kourallisessa laitteita, mutta yleistyy kovaa
          vauhtia. Lähitulevaisuudessa älypuhelimien rinnalle on nousemassa myös
          muita eSIM-päätelaitteita, kuten älykellot. DNA on valmis
          tulevaisuuden muutoksille eSIM-palvelunsa myötä.
        </TextArea>
        <Header4>Tietoturva</Header4>
        <TextArea>
          DNA suhtautuu myös{" "}
          <a href="/blogi/viruksen-poisto-puhelimesta/">tietoturva-asioihin</a>{" "}
          niiden vaatimalla vakavuudella ja tarjoaa kattavan DNA Turvapaketin
          kodin kaikille laitteille. DNA Turvapaketti tuo laajan suojan viruksia
          ja erilaisia haittaohjelmia vastaan, ja huomioi myös lapset antamalla
          lisäturvaa perheen pienimpien käyttäjien nettisurffailuun. DNA
          Turvapaketti Plus -palvelun tuottaa kotimainen F-Secure, ja sen avulla
          voidaan suojata yksi tai useampi laite. Turvapaketin sisältö ja
          laajuus voidaan valita eri vaihtoehdoista, ja valitun paketin
          laajuutta on helppo päivittää elämäntilanteiden muuttuessa.
        </TextArea>
        <Header4>Mobiilivarmenne</Header4>
        <TextArea>
          DNA:lta saa lisäpalveluna myös{" "}
          <a href="https://www.dna.fi/mobiilivarmenne">mobiilivarmenteen</a>.
          DNA:n Mobiilivarmenne toimii sähköisenä henkilöllisyystodistuksenasi
          päätelaitteessasi. Mobiilivarmenteen avulla pääsee helposti ja
          turvallisesti tunnistautumaan ja kirjautumaan erilaisiin
          verkkopalveluihin suoraan omalla matkapuhelimella silloin, kun
          nettiasiointi sitä vaatii. Mobiilivarmenteen käyttö on helppoa. Sitä
          hyödyntääksesi et tarvitse muuta kuin oman puhelinnumerosi, sekä itse
          valitsemasi nelinumeroisen tunnusluvun. Mikä parasta, DNA tarjoaa
          Mobiilivarmenteen asiakkaidensa käyttöön yhdeksi vuodeksi täysin
          veloituksetta. Mobiilivarmenteen normaali hinta on 1,99€ kuukaudessa,
          liittymää avattaessa veloitetaan 3,90 euron kytkentämaksu.
        </TextArea>
        <Header4>Muut lisäpalvelut</Header4>
        <TextArea>
          DNA tarjoaa luonnollisesti myös kaikki perinteiset liittymien
          <a href="https://www.dna.fi/lisapalvelut">lisäpalvelut</a>, kuten
          rajoitukset, estot, vastaajat, multimediaviestit ja numeronsiirrot.
          Puhelimien käyttöä ja suojausta voidaan helpottaa erilaisilla esto- ja
          rajoituspalveluilla. Liittymään saa kätevästi asetettua
          palvelukohtaisia estoja esimerkiksi maksullisiin palvelu- ja
          viihdenumeroihin soittamisen estämiseksi. Myös tekstiviestejä on
          mahdollista rajoittaa ja estää tarvittaessa tekstiviestiliikenne
          maksullisiin palvelu- ja viihdenumeroihin. Tämän lisäksi on
          mahdollista estää mobiilimaksaminen sekä maksullisten sovellusten
          tilaaminen ja lataaminen. Näiden lisäpalveluiden avulla on entistä
          helpompaa varmistaa, ettei puhelinlaskuun ilmesty ikäviä yllätyksiä.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          DNA on jo lähes 20 vuotta toiminut Suomen matkaviestintämarkkinoiden
          edelläkävijänä. Haastajana kuvioihin mukaan tullut markkinoiden
          väriläiskä on vuosien saatossa raivannut paikkansa alan huipulle.
          Yritykseltä ei ole puuttunut rohkeutta tuoda alalle uusia
          innovaatioita, ja tämä on palkittu markkinaosuuden tasaisella
          kasvulla.
        </TextArea>
        <TextArea>
          DNA on oiva valinta, sillä yritys tarjoaa mukavan kattauksen hyviä
          liittymävaihtoehtoja kilpailukykyiseen hintaan. DNA tarjoaa liittymän
          aidosti kaikenlaisiin tarpeisiin vauvasta vaariin. Yrityksen
          asiakaspalvelu toimii jouhevasti ja kattava myymäläverkosto varmistaa
          sen, että asioimaan pääsee tarvittaessa myös kasvotusten.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(filter: { img: { eq: "dna.jpg" } }, sort: { fields: price }) {
      nodes {
        benefits
        callPrice
        id
        img
        internetSpeed
        internet
        name
        price
        textPrice
        type
        url
        openFee
      }
    }
  }
`

export default FrontPage
